import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Heading from '@/components/common/Heading';

import { FooterContactsType } from '@/types';

interface IFooterContactsProps {
  footerContacts: FooterContactsType[];
}

const FooterContacts: FC<IFooterContactsProps> = ({ footerContacts }) => {
  return (
    <div className="md:w-[25%]">
      <Heading headingLevel="h3" className="mb-4 md:mb-8 text-xl">
        Contacts
      </Heading>
      <address>
        <ul className={''}>
          {footerContacts.map(
            ({
              id,
              icon,
              text,
              title,
              path,
              ariaLabel,
              address,
            }: FooterContactsType) => (
              <li key={id} className={'mb-4 '}>
                <address className="not-italic flex items-baseline">
                  <Image
                    className=" w-4 h-4 mr-2 "
                    src={`/assets/icons/contactsAddress/${icon}`}
                    width={100}
                    height={100}
                    alt={`${title}`}
                  />
                  <div>
                    <p className=" font-semibold text-[1.1rem]">{text}</p>
                    {address && (
                      <div className=" flex-col py-2">
                        {address.map((i, id) => (
                          <p
                            key={id}
                            className="custom-p text-[0.9rem] leading-6  "
                          >
                            {i}
                          </p>
                        ))}
                      </div>
                    )}
                    {path && (
                      <Link
                        className="custom-p text-[0.9rem] leading-8 py-2 hover:text-[--accent-color]"
                        href={`${path}`}
                        aria-label={ariaLabel}
                      >
                        {title}
                      </Link>
                    )}
                  </div>
                </address>
              </li>
            )
          )}
        </ul>
      </address>
    </div>
  );
};

export default FooterContacts;
