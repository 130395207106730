import { FC, AnchorHTMLAttributes } from 'react';
import Link from 'next/link';
// import Image from 'next/image';
import {
  /* urlLogo, */ ReactComponent as ImgLogo,
} from 'public/assets/icons/cs-logo.svg';

interface ILogoProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  closeMenu?: () => void;
}

//TODO add aria label for logo
const Logo: FC<ILogoProps> = ({ className, closeMenu }) => {
  return (
    <>
      <Link
        href="/"
        className={className}
        onClick={closeMenu}
        aria-label="go to home page"
      >
        <ImgLogo width={143} height={50} aria-label="Logo code-solution" />
        {/* <Image
          src={urlLogo}
          alt="logo"
          width={100}
          height={0}
          className="fill-red-500"
        /> */}
      </Link>
    </>
  );
};

export default Logo;
