import { FC } from 'react';
import { IconType } from 'react-icons';

// import ReactIcons from '@/components/common/Icon/react-icon';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { HiOutlineMail } from 'react-icons/hi';
import { HiComputerDesktop } from 'react-icons/hi2';
import { FiSearch, FiPhoneCall, FiVideo } from 'react-icons/fi';
import { SlBasket } from 'react-icons/sl';
import { VscTools } from 'react-icons/vsc';
import { MdOutlineMiscellaneousServices } from 'react-icons/md';
// import { ReactComponent as IconPhone } from 'public/assets/icons/form/phone-call.svg';
// import { ReactComponent as IconEmail } from 'public/assets/icons/form/mail.svg';
// import { ReactComponent as IconVideo } from 'public/assets/icons/form/video.svg';

interface IIconProps {
  iconName: string;
  className?: string;
}

const iconMap: { [key: string]: IconType } = {
  'Web Design': HiOutlineRocketLaunch,
  'Web Development': HiComputerDesktop,
  SEO: FiSearch,
  'E-commerce': SlBasket,
  'Web Support': VscTools,
  'Migration & Update': MdOutlineMiscellaneousServices,
  Phone: FiPhoneCall,
  Email: HiOutlineMail,
  'Online Meeting': FiVideo,
};

const Icon: FC<IIconProps> = ({ iconName, className, ...props }) => {
  // const IconComponent: IconType =
  //   ReactIcons[iconName as keyof typeof ReactIcons];

  const IconComponent = iconMap[iconName];
  if (!IconComponent) {
    return null;
  }

  return (
    <>
      <IconComponent className={className} {...props} />
    </>
  );
};

export default Icon;
