import { FC } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useField, useFormikContext } from 'formik';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Input from '@/components/FormModal/CustomForm/Input';

interface IDatePickerProps extends ReactDatePickerProps {
  label: string;
  name: string;
}

const CustomDatePicker: FC<IDatePickerProps> = props => {
  const [field /* , meta */] = useField(props.name);
  const { setFieldValue } = useFormikContext();

  return (
    <div className={`relative flex flex-col card-set [--card-gap:10px]`}>
      <DatePicker
        id={props.name}
        dateFormat="dd.MM.yyyy"
        calendarStartDay={1}
        minDate={new Date()}
        maxDate={new Date('2050/12/31')}
        selected={field.value || null}
        className={`peer
         h-10
         border-b
         border-[#e1e3e2] text-[--form-input-color]
         focus:outline-none focus:border-blue-500
         placeholder-transparent
         `}
        {...props}
        customInput={<Input type="text" {...props} />}
        // popperPlacement="top"
        //INFO This params will help you: 'auto', 'auto-left', 'auto-right', 'bottom', 'bottom-end', 'bottom-start', 'left', 'left-end', 'left-start', 'right', 'right-end', 'right-start', 'top', 'top-end', 'top-start'
        popperPlacement="bottom-start"
        // {...field}
      />
      {field.value !== '' && (
        <>
          <RxCross2
            className="absolute top-9 right-2 text-[0.8rem] text-[--form-label-color]"
            onClick={() => {
              setFieldValue(props.name, '');
            }}
          />
        </>
      )}
      {/* {meta.touched && meta.error && (
        <div className="text-red-500 text-sm mt-1">{meta.error}</div>
      )} */}
    </div>
  );
};

export default CustomDatePicker;
