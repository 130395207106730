import { use100vh } from 'react-div-100vh';

const useScrollLock = () => {
  const height = use100vh();

  const lockScroll = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.maxHeight = `${height}px`;
  };

  const unlockScroll = () => (document.body.style.overflow = '');

  // const lockScroll = () => {
  //   const layout = document.querySelector('.block-scroll') as HTMLDivElement;
  //   if (!layout) return;
  //   layout.style.overflow = 'hidden';
  // };

  // const unlockScroll = () => {
  //   const layout = document.querySelector('.block-scroll') as HTMLDivElement;
  //   if (!layout) return;
  //   layout.style.overflow = '';
  // };

  return { lockScroll, unlockScroll, height };
};

export default useScrollLock;
