import { FC, HTMLAttributes, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FeatureType } from '@/types';
import pages from '@/db/index/pages.json';
import features from '@/db/index/features.json';
// import { RiArrowDownSFill } from 'react-icons/ri';

interface INavbarProps extends HTMLAttributes<HTMLUListElement> {
  styleLI?: string;
  closeMenu?: () => void;
  screen?: 'mobile' | 'tab' | 'desktop';
}

//TODO add aria label for Link's
const Navbar: FC<INavbarProps> = ({
  className,
  styleLI,
  closeMenu,
  screen,
}) => {
  //active link
  const { pathname } = useRouter();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleLinkClick = () => {
    if (screen === 'mobile' && closeMenu) {
      return closeMenu();
    }
    setDropdownOpen(false);
  };
  return (
    <>
      <ul className={['', className].join(' ')}>
        {pages.map(({ id, title, path, ariaLabel }) => (
          <li
            key={id}
            className={[
              `group/item relative max-lg:w-[100%] max-lg:py-3 max-lg:first:pb-0  duration-500
              `,
              styleLI,
            ].join(' ')}
            onMouseEnter={
              screen !== 'mobile' ? () => setDropdownOpen(true) : undefined
            }
            onMouseLeave={
              screen !== 'mobile'
                ? () => () => setDropdownOpen(false)
                : undefined
            }
          >
            <Link
              onClick={handleLinkClick}
              href={path}
              className={`max-lg:block max-lg:w-[100%] max-lg:font-medium link-hover focus:[--start:0%] hover:[--start:0%] lg:py-[35px]
              ${pathname.includes(path) ? '[--start:0%]' : ''} `}
              aria-label={ariaLabel}
            >
              {title}
            </Link>
            {path === '/services' &&
              (screen === 'mobile' || isDropdownOpen) && (
                <ul
                  className={`w-full max-lg:mt-4 mt-2 max-lg:mb-4 max-lg:ml-4 lg:absolute lg:top-[40px] lg:left-[-20%] lg:bg-[#fff] lg:min-w-[220px] lg:rounded-b-[10px] 
                lg:hidden 
                  ${
                    screen !== 'mobile'
                      ? 'shadow-[5px_5px_10px_-5px_#0000003e]'
                      : 'shadow-none'
                  }
                lg:group-hover/item:block
                lg:opacity-0 lg:group-hover/item:opacity-100
                transition duration-[--duration]`}
                >
                  {features.map(
                    ({
                      featureId,
                      featureTittle,
                      featureLink,
                      featureIcon,
                    }: FeatureType) => (
                      <li
                        key={featureId}
                        className="group/subMenu flex items-center p-3 max-lg:duration-300 
                        lg:leading-[1.2rem]"
                      >
                        <Image
                          src={`/assets/icons/features/${featureIcon}`}
                          alt={featureTittle}
                          width={100}
                          height={100}
                          className="w-[20px] mr-2 lg:hidden"
                          priority
                        />
                        <Link
                          href={`/services/${featureLink}`}
                          onClick={handleLinkClick}
                          className={`block w-[100%] text-[14px] max-lg:leading-[1.6rem] leading-[1.2rem] link-hover ${
                            featureLink === pathname.split('/services/')[1]
                              ? '[--start:0%]'
                              : ''
                          }
                            group-hover/subMenu:[--start:0%] group-focus/subMenu:[--start:0%] focus:[--start:0%] link-hover`}
                        >
                          {featureTittle}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              )}
          </li>
        ))}
      </ul>
    </>
  );
};

//INFO dropdown menu
// const Navbar: FC<INavbarProps> = ({
//   className,
//   styleLI,
//   closeMenu,
//   screen,
// }) => {
//   //active link

//   const { pathname } = useRouter();
//   const [isDropdownOpen, setDropdownOpen] = useState(false);

//   const handleLinkClick = () => {
//     if (screen === 'mobile' && closeMenu) {
//       return closeMenu();
//     }
//     setDropdownOpen(false);
//   };

//   return (
//     <>
//       <ul className={['', className].join(' ')}>
//         {pages.map(({ id, title, path }) => (
//           <li
//             key={id}
//             className={[`group`, styleLI].join(' ')}
//             onMouseEnter={
//               screen !== 'mobile' ? () => setDropdownOpen(true) : undefined
//             }
//             onMouseLeave={
//               screen !== 'mobile'
//                 ? () => () => setDropdownOpen(false)
//                 : undefined
//             }
//           >
//             <Link
//               onClick={handleLinkClick}
//               href={path}
//               className="block hover:bg-gray-200 p-2"
//             >
//               {title}
//             </Link>
//             {path === '/services' &&
//               (screen === 'mobile' || isDropdownOpen) && (
//                 <div className={``}>
//                   {/* className={`${screen === 'mobile' ? 'block' : 'hidden'} `} */}
//                   <ul className="absolute left-[60%] top-[50px] bg-gray-100 lg:hidden group-hover:block">
//                     {features.map(
//                       ({ featureId, featureTittle, featureLink }) => (
//                         <li key={featureId}>
//                           <Link
//                             href={`/services/${featureLink}`}
//                             className="block p-2"
//                             onClick={handleLinkClick}
//                           >
//                             {featureTittle}
//                           </Link>
//                         </li>
//                       )
//                     )}
//                   </ul>
//                 </div>
//               )}
//           </li>
//         ))}
//       </ul>
//     </>
//   );
// };

export default Navbar;
