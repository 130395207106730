import { FC, useState, useEffect } from 'react';
import { AiOutlineAlignLeft } from 'react-icons/ai';
import useScrollLock from '@/hooks/useScrollLock';
import useMatchMedia from '@/hooks/useMatchMedia';
import useToggleModal from '@/hooks/useToggleModal';

import ClientOnly from '@/components/common/ClientOnly';
import Container from '@/components/common/Container';
import Button from '@/components/common/Button';
import Logo from '@/components/Logo';
import MobileMenu from '@/components/NavMenu/MobileMenu';
import Navbar from '@/components/NavMenu/Navbar';
import FormModal from '@/components/FormModal';
import { ReactComponent as IconFormBtn } from 'public/assets/icons/form.svg';

interface IHeaderProps {
  // children?: ReactNode;
}

const Header: FC<IHeaderProps> = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [shadow, setShadow] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();
  // const { lockScroll, unlockScroll, height } = useScrollLock();
  const { isLG } = useMatchMedia();
  const { isOpen, openModal, closeModal, handleKeyDown, handleBackdropClick } =
    useToggleModal();

  const handleShadow = () => {
    if (window.scrollY >= 90) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleShadow);
    return () => {
      window.removeEventListener('scroll', handleShadow);
    };
  }, []);

  const openMenu = () => {
    setMobileMenuIsOpen(true);
    lockScroll();
  };

  const closeMenu = () => {
    setMobileMenuIsOpen(false);
    unlockScroll();
  };

  //TODO add aria-label
  return (
    <header
      className={`
        bg-white 
      ${
        shadow
          ? 'fixed w-full h-[--h-header] z-[50] shadow-xl ease-in-out duration-300'
          : 'fixed w-full h-20 z-[100] '
      }`}
    >
      <Container>
        <nav className="flex items-center py-[15px]">
          <Logo className="" />
          <Navbar
            className="hidden lg:flex card-set ml-auto"
            styleLI="card-set-item [--card-item:4] ml-[20px] text-base"
          />
          <Button
            onClick={openModal}
            className="max-md:p-4 max-md:w-auto absolute -bottom-[85vh] right-6 
            max-md:bg-[var(--btn-bg-color)] max-md:text-[var(--btn-color)] 
            max-md:animate-bounce max-md:animation-delay-[3s] duration-[--duration]
            md:static md:flex ml-auto lg:ml-[20px] 
            "
            secondary
          >
            {/* max-md:fill-white 
            max-md:hover:text-[var(--btn-hover-color)] max-md:hover:bg-[var(--btn-hover-bg-color)] max-md:focus:text-[var(--btn-hover-color)] */}
            {/* // max-md:hover:fill-[--accent-color] max-md:focus:fill-[--accent-color] */}
            <span className="max-md:visually-hidden">Get In Touch</span>
            <IconFormBtn className="md:hidden fill-current" />
          </Button>
          <AiOutlineAlignLeft
            onClick={openMenu}
            size={25}
            className="ml-auto md:ml-5 lg:hidden"
          />
          {/* Mobile Menu */}
          <ClientOnly>
            {/* Overlay */}
            <div
              onClick={closeMenu}
              className={
                mobileMenuIsOpen
                  ? 'lg:hidden fixed left-0 top-0 w-full h-screen bg-neutral-500/40 transition duration-[var(--duration)]  ease-in-out'
                  : ''
              }
            >
              <div
                onClick={e => {
                  e.stopPropagation();
                }}
                className={`bg-white absolute top-0 -left-[80vw] w-[75%] h-full ${
                  mobileMenuIsOpen
                    ? 'translate-x-[80vw] opacity-100 transition duration-[var(--duration)] ease-in-out'
                    : 'opacity-50'
                }
              md:w-[60%] 
              lg:hidden
              `}
              >
                {mobileMenuIsOpen && !isLG && (
                  <>
                    <MobileMenu closeMenu={closeMenu} />
                    {/* //INFO Determining screen height
                    <div>{height}</div> */}
                  </>
                )}
              </div>
            </div>
            {isOpen && (
              <FormModal
                handleKeyDown={handleKeyDown}
                handleBackdropClick={handleBackdropClick}
                closeModal={closeModal}
              />
            )}
          </ClientOnly>
        </nav>
      </Container>
    </header>
  );
};

export default Header;
