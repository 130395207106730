import { FC, ReactNode, ButtonHTMLAttributes } from 'react';

interface IButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  children: ReactNode;
}

const ButtonIcon: FC<IButtonIconProps> = ({
  className,
  id,
  onClick,
  children,
}) => {
  return (
    <>
      <button
        className={[
          'p-2 text-inherit bg-inherit border-0 border-none rounded-full hover:border-0 hover:border-none  focus:outline-0 focus:outline-none',
          className,
        ].join(' ')}
        id={id}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

export default ButtonIcon;
