import { FC } from 'react';
import { FieldAttributes } from 'formik';

import { RxCircle } from 'react-icons/rx';
import { RxCheckCircled } from 'react-icons/rx';

import Icon from '@/components/common/Icon';

interface ICheckboxProps extends FieldAttributes<any> {
  icon: string;
}

const CheckboxRadioBtn: FC<ICheckboxProps> = ({ field, type }) => {
  return (
    <>
      <label className="cursor-pointer outline-none">
        <input type={type} {...field} className="visually-hidden" />
        <div
          className={`relative border h-full rounded-md p-2 bg-[#fcfcfc] ${
            field.checked ? 'border-[--accent-color]' : 'border-[#e1e3e2]'
          }`}
        >
          {/* ${
            type === 'radio' ? '' : 'h-full'
          } */}
          <div className="absolute">
            <span>
              {field.checked ? (
                <RxCheckCircled className="text-[--accent-color]" />
              ) : (
                <RxCircle className="text-[#49564f]" />
              )}
            </span>
          </div>
          <div
            className={`items-center ${
              field.checked ? 'text-[--accent-color]' : 'text-[#49564f]'
            } flex flex-col justify-center items-center`}
          >
            <Icon iconName={field.value} className="mb-3 w-7 h-7" />
            <span className="text-[0.8rem] text-center">{field.value}</span>
          </div>
        </div>
      </label>
    </>
  );
};

export default CheckboxRadioBtn;
