import { FC, ReactNode, HTMLAttributes } from 'react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
interface ILayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  return (
    <>
      <div className="flex flex-col h-full min-h-screen">
        <Header />
        <main className={`flex flex-col flex-auto mt-[--h-header] `}>
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
