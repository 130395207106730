import { FC } from 'react';
import { useField, FieldAttributes } from 'formik';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

interface IPhoneInputFieldProps extends PhoneInputProps, FieldAttributes<any> {}

const PhoneInputField: FC<IPhoneInputFieldProps> = ({ ...props }) => {
  const [field, meta, helpers] = useField<string>(props.name);

  const handlePhoneChange = (value: string) => {
    helpers.setValue(value);
  };

  const handlePhoneBlur = () => {
    helpers.setTouched(true);
  };
  return (
    <>
      <div
        className={`group/item relative flex flex-col card-set [--card-gap:6px] mt-2`}
      >
        <label
          className={`text-[0.8rem] text-[--form-label-color]
            duration-[--duration]
          `}
        >
          {props.label}
          <span className="text-base text-red-500">*</span>
        </label>

        <PhoneInput
          inputProps={{
            id: props.name,
            required: true,
            placeholder: 'Enter your phone',
          }}
          country={props.country}
          value={field.value}
          specialLabel={''}
          // preferredCountries={['ca', 'us', 'ua']}
          containerClass="relative phone-input w-full"
          inputClass={`w-full h-10 pl-[32px] border border-[#e1e3e2] bg-[#fcfcfc]
          rounded-lg
          text-[--form-input-color] 
          focus:outline-none focus:border-[--accent-color]
          placeholder-transparent`}
          buttonClass="absolute top-[45%] left-[0.5px] translate-y-[-50%] px-2 py-[9px] rounded-l-lg"
          disableDropdown
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          {...props.input}
          {...props.meta}
          {...props.custom}
        />

        {meta.touched && meta.error ? (
          <div className="error-form">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export default PhoneInputField;
