import { FC, ReactNode, ButtonHTMLAttributes } from 'react';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  onClick: () => void;
  secondary?: boolean;
}

const Button: FC<IButtonProps> = ({
  className,
  id,
  type = 'button',
  disabled = false,
  onClick,
  children,
  secondary,
}) => {
  return (
    <>
      <button
        className={[
          `${
            !secondary
              ? 'custom-button custom-button-hover'
              : 'custom-button-secondary custom-button-secondary-hover'
          }`,
          className,
        ].join(' ')}
        id={id}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
