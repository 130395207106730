import { FC } from 'react';
import Link from 'next/link';

import { FooterType } from '@/types';

import Heading from '@/components/common/Heading';

interface IFooterServicesProps {
  footerServices: FooterType[];
}

const FooterServices: FC<IFooterServicesProps> = ({ footerServices }) => {
  return (
    <div className="md:w-[25%]">
      <Heading headingLevel="h3" className="mb-4 md:mb-8 text-xl">
        Services
      </Heading>
      <ul className="mr-6">
        {footerServices.map(({ id, title, path, ariaLabel }: FooterType) => (
          <li key={id}>
            <Link
              href={`${path}`}
              className="custom-p text-[0.9rem] leading-8 py-2 hover:text-[--accent-color]"
              aria-label={ariaLabel}
            >
              <span className="visually-hidden">{ariaLabel}</span>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterServices;
