import '@/styles/globals.css';
import '@/styles/PhoneInput2Flag.css';
import '@/styles/DataPicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Script from 'next/script';

import { FunctionComponent, PropsWithChildren } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import Layout from '@/components/Layout';
import { Inter } from 'next/font/google';
const inter = Inter({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
});

type ComponentWithPageLayout = AppProps & {
  Component: AppProps['Component'] & {
    PageLayout?: FunctionComponent<PropsWithChildren>;
  };
};

export default function App({ Component, pageProps }: ComponentWithPageLayout) {
  return (
    <>
      <Head>
        <title>
          Code Solution - Web Development and Internet Promotion Experts
        </title>
        <meta
          name="description"
          content="Code Solution is a leading web development and internet promotion agency. We deliver exceptional websites and effective online marketing strategies to help businesses thrive."
        ></meta>
        <meta
          name="keywords"
          content="web development, internet promotion, Code Solution"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <link rel="icon" href="/favicon.ico"></link>
      </Head>
      <Script
        strategy="afterInteractive"
        src={'https://www.googletagmanager.com/gtag/js?id=G-QBD5N30DMP'}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-QBD5N30DMP');
        `}
      </Script>
      <Layout className={`${inter.className}`}>
        {Component.PageLayout ? (
          <Component.PageLayout>
            <Component {...pageProps} />
          </Component.PageLayout>
        ) : (
          <>
            <Component {...pageProps} />
          </>
        )}
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          // pauseOnFocusLoss
          draggable
          // pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  );
}
