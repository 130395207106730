import {
  HTMLAttributes,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { createPortal } from 'react-dom';
import useScrollLock from '@/hooks/useScrollLock';
import { motion } from 'framer-motion';

import { Inter } from 'next/font/google';
const inter = Inter({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
});

interface IModalProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  handleKeyDown: (e: KeyboardEvent) => void;
  handleBackdropClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const Modal: FC<IModalProps> = ({
  className,
  handleKeyDown,
  handleBackdropClick,
  children,
}) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    ref.current = document.querySelector('#modal-root');
    setMounted(true);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    lockScroll();
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      unlockScroll();
    };
  }, [unlockScroll, handleKeyDown, lockScroll]);

  return mounted && ref.current
    ? createPortal(
        <>
          <motion.div
            className={`${inter.className} transition-transform fixed z-[100] top-0 left-0 
            w-[100vw] h-[100vh] bg-neutral-500/40 backdrop-blur
            flex justify-center items-start overflow-y-scroll
            md:pt-12 pb-24 md:items-start
            opacity-100`}
            onClick={handleBackdropClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={`rounded-3xl bg-white p-0 transition-transform${className}`}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.3 }}
            >
              {children}
            </motion.div>
          </motion.div>
        </>,
        ref.current
      )
    : null;
};
export default Modal;
