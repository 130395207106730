import {
  // FC,
  forwardRef,
  // Ref,
  ForwardRefRenderFunction,
} from 'react';
import { useField, FieldAttributes, Field } from 'formik';

interface IInputProps extends FieldAttributes<any> {}

//INFO if we want export without forwardRef we need to describe FC component as bellow
// const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>(
// (props: IInputProps, ref: Ref<HTMLDivElement>) => {
const Input: ForwardRefRenderFunction<HTMLDivElement, IInputProps> = (
  { onClick, ...props },
  ref
) => {
  const [field, meta] = useField<boolean>(props.name);
  //INFO How to extend a component?
  // const { component: Component = 'input', ...rest } = props;
  return (
    <>
      <div
        className={`relative flex flex-col card-set [--card-gap:8px]`}
        ref={ref}
      >
        <label
          htmlFor={props.name}
          className={`
          ${props.as === 'textarea' ? 'left-2' : 'left-0'}
          text-[0.8rem] text-[--form-label-color]
          `}
        >
          {props.label}
          {props.required && <span className="text-base text-red-500">*</span>}
        </label>

        <Field
          id={props.name}
          {...field}
          {...props}
          type="text"
          component={props.component}
          className={`peer rounded-lg border px-2
          ${props.as ? 'p-2 leading-[1.25rem]' : 'h-10'}
          border-[#e1e3e2] text-[--form-input-color]
          bg-[#fcfcfc]
          focus:outline-none focus:border-[--accent-color]
          focus:bg-[#fcfcfc]
          placeholder-transparent
          `}
          // ${meta.touched && meta.error && 'invalid:border-[--form-text-error-color]'}
          placeholder="Name"
          // {...rest}
          onClick={onClick}
        />

        {meta.touched && meta.error ? (
          <div className="error-form">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};
// );
Input.displayName = 'Input';

export default forwardRef(Input);
