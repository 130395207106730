import { FC, HtmlHTMLAttributes } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import Logo from '@/components/Logo';
import Navbar from '@/components/NavMenu/Navbar';

interface IMobileMenuProps extends HtmlHTMLAttributes<HTMLDivElement> {
  closeMenu: () => void;
}

//TODO add aria-label
const MobileMenu: FC<IMobileMenuProps> = ({ className, closeMenu }) => {
  return (
    <>
      <div
        className={[
          'flex flex-col justify-center px-6 py-3 bg-white',
          className,
        ].join(' ')}
      >
        <AiOutlineClose
          onClick={closeMenu}
          size={25}
          className="absolute top-4 right-4"
        />
        <Logo className="max-lg:mb-5" closeMenu={closeMenu} />
        <Navbar
          className={'card-set lg:[--card-gap:10px]'}
          styleLI={'card-set-item'}
          closeMenu={closeMenu}
          screen={'mobile'}
        />
      </div>
    </>
  );
};

export default MobileMenu;
