import Container from '@/components/common/Container';
import Logo from '@/components/Logo/Logo';
import FooterCompany from '@/components/Footer/FooterCompany';
import FooterServices from '@/components/Footer/FooterServices';
import FooterContacts from '@/components/Footer/FooterContacts';

import footerCompany from '@/db/footer/footerCompany.json';
import footerServices from '@/db/footer/footerServices.json';
import footerContacts from '@/db/footer/footerContacts.json';

const Footer = () => {
  return (
    <>
      <footer className="bg-[#fafafa] p-[50px_0_10px] ">
        <Container>
          <div className="max-md:flex-col max-md:gap-[3rem] flex ">
            <div className=" md:w-[25%]">
              <div className="mb-8">
                <Logo />
              </div>
              <p className="custom-p max-md:leading-[1.8rem] text-[1.5rem] font-extrabold w-[80%] text-[#000] ">
                Transform your vision into digital reality
              </p>
            </div>
            <FooterCompany footerCompany={footerCompany} />
            <FooterServices footerServices={footerServices} />
            <FooterContacts footerContacts={footerContacts} />
          </div>
          <p className="max-md:text-center max-md:mt-3 custom-p text-[12px]">
            &copy; 2023 Code Solution
          </p>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
