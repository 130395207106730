import { FC, HTMLAttributes } from 'react';

interface ISpinnerProps extends HTMLAttributes<HTMLDivElement> {
  size: number;
  width: number;
  height: number;
  color: string;
}

const Spinner: FC<ISpinnerProps> = ({
  size,
  width,
  height,
  color,
  className,
}) => {
  return (
    <>
      <div className="mx-auto">
        <div
          style={
            {
              '--size-spinner': `${size}px`,
              '--width-spinner': `${width}px`,
              '--height-spinner': `${height}px`,
              left: '50%',
              transform: `translate(calc(-50% + ${-size - size / 2}px), -50%)`,
              color: color,
            } as React.CSSProperties
          }
          className={[
            `relative text-[#ff0000] inline-block w-[--size-spinner] h-[--size-spinner]`,
            className,
          ].join('')}
        >
          <div
            className={`spinner-item-animate after:content-'' rotate-[0deg] animation-delay-[-1.1s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[30deg] animation-delay-[-1s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[60deg] animation-delay-[-0.9s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[90deg] animation-delay-[-0.8s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[120deg] animation-delay-[-0.7s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[150deg] animation-delay-[-0.6s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[180deg] animation-delay-[-0.5s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[210deg] animation-delay-[-0.4s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[240deg] animation-delay-[-0.3s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[270deg] animation-delay-[-0.2s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[300deg] animation-delay-[-0.1s]`}
          ></div>
          <div
            className={`spinner-item-animate after:content-'' rotate-[330deg] animation-delay-[-0s]`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
