import { FC, useState, useEffect } from 'react';
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from 'formik';
import axios from 'axios';
import { formatDate } from '@/utils/helpers';
import { toast } from 'react-toastify';

import Button from '@/components/common/Button';
import Heading from '@/components/common/Heading/Heading';
import * as Yup from 'yup';
import PhoneInputField from '@/components/FormModal/CustomForm/PhoneInputField';
import Input from '@/components/FormModal/CustomForm/Input';
import CheckboxRadioBtn from '@/components/FormModal/CustomForm/CheckboxRadioBtn';
import CustomDatePicker from '@/components/FormModal/CustomForm/Input/CustomDatePicker';
import Spinner from '@/components/common/Spinner';

import { checkboxType, radioBtnType, FormValuesType } from '@/types';

import checkbox from '@/db/form/checkbox.json';
import radioBtn from '@/db/form/radiobtn.json';

interface IFormProps {
  closeModal: () => void;
}
type IPapiType = {
  country_code: string;
  country_calling_code: string;
};

const CustomForm: FC<IFormProps> = ({ closeModal }) => {
  const [country, setCountry] = useState<IPapiType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ipapi.co/json');
        const responseData: IPapiType = await response.json();
        setCountry(responseData);
        // setCountry(responseData.country_code.toLowerCase());
        // setDialCode(responseData.country_calling_code);
      } catch (error) {
        setCountry({ country_code: 'us', country_calling_code: '+1' });
        throw new Error('Failed request');
      }
    };
    fetchData();
  }, []);

  const initialValues: FormValuesType = {
    clientName: '',
    company: '',
    phone: !country ? '' : country?.country_calling_code,
    email: '',
    services: [],
    message: '',
    deadlines: '',
    connection: '',
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [isError, setIsError] = useState<boolean>(false);
  const validationSchema = Yup.object().shape({
    clientName: Yup.string()
      .max(15, 'Have to be 15 characters or less')
      .required('This field is required'),
    company: Yup.string().max(20, 'Have to be 20 characters or less'),
    phone: Yup.string()
      .min(10, 'Please input proper number')
      .required('This field is required'),
    email: Yup.string()
      .email('Please input proper email')
      .required('This field  is required'),
    services: Yup.array().min(1, 'Please select at least one option'),
    message: Yup.string(),
    deadlines: Yup.date(),
    connection: Yup.string().required('Please select at least one option'),
  });

  const handleSubmit = async (
    values: FormValuesType,
    { setSubmitting, resetForm }: FormikHelpers<FormValuesType>
  ) => {
    // Access the country data and dial code
    const sendDate = { ...values, deadlines: formatDate(values.deadlines) };
    try {
      setIsLoading(true);
      await axios.post('/api/send-form', sendDate);
      await new Promise(resolve => setTimeout(resolve, 2000)); // Simulating asynchronous operation
      setSubmitting(false);
      resetForm();
      // setIsError(false);
      toast.success(
        <p>
          <strong>Thank you!</strong>
          <br /> We have received your request and will contact you shortly. We
          strive to provide you with quality services and will respond to your
          request as soon as possible.
          <br />
          <strong>Thank you for choosing our company!</strong>
        </p>
      );
      closeModal();
    } catch (error) {
      // setIsError(true);
      //INFO Toastify
      toast.error(
        <p>
          <strong>Sorry, an error occurred while sending your request.</strong>
          <br />
          Please try again or contact us directly using the contact details
          provided. We will make every effort to resolve this issue.
          <br />
          <strong>Thank you for your understanding and cooperation.</strong>
        </p>
      );
      throw new Error('Unsuccessfully sending!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading headingLevel="h3" className="pt-4 pl-4">
        Request Form
      </Heading>
      <Formik<FormValuesType>
        initialValues={initialValues}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isValid, dirty, setFieldValue, handleSubmit }) => {
          return (
            <Form className="p-[1.2rem] overflow-hidden">
              <div className="card-set [--card-gap:6px] flex-col">
                {/* input name */}
                <Input
                  required
                  type="text"
                  name="clientName"
                  label="What`s your name?"
                />
                {/* input company name */}
                <Input type="text" name="company" label="Company name" />
                {/* input phone */}
                <PhoneInputField
                  required
                  name="phone"
                  label="Phone"
                  country={country?.country_code}
                />
                {/* input email */}
                <Input required type="email" name="email" label="Email" />
                {/* checkbox - services*/}
                <div className="card-set flex-col [--card-gap:8px]">
                  <label
                    id="checkbox-group"
                    className="text-[0.8rem] text-[--form-label-color]"
                  >
                    What do you need help with?
                    <span className="text-[0.8rem] text-red-500">*</span>
                  </label>
                  <ul
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="card-set [--card-item:3] [--card-gap:10px]"
                  >
                    {checkbox.map(({ name, value }: checkboxType) => (
                      <li key={value} className="card-set-item">
                        <Field
                          type="checkbox"
                          name={name}
                          value={value}
                          component={CheckboxRadioBtn}
                        />
                      </li>
                    ))}
                  </ul>
                  <ErrorMessage
                    className="error-form"
                    name="services"
                    component="div"
                  />
                </div>
                {/*text area brief */}
                <Input
                  as="textarea"
                  rows={4}
                  name="message"
                  label="Tell us more about your project"
                />
                {/*text area deadlines */}
                <CustomDatePicker
                  name="deadlines"
                  label="Do you have a deadline?"
                  onChange={(date: Date | null) => {
                    if (!date) return;
                    setFieldValue('deadlines', date);
                  }}
                />
                {/* radio buttons - connection*/}
                <div className="card-set flex-col [--card-gap:8px]">
                  <label
                    id="checkbox-group"
                    className="text-[0.8rem] text-[--form-label-color]"
                  >
                    Prefered method of communication
                    <span className="text-[0.8rem] text-red-500">*</span>
                  </label>

                  <ul className="card-set [--card-item:3] [--card-gap:10px]">
                    {radioBtn.map(({ name, value, icon }: radioBtnType) => (
                      <li key={value} className="card-set-item">
                        <Field
                          id={value}
                          type="radio"
                          name={name}
                          value={value}
                          icon={icon}
                          component={CheckboxRadioBtn}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <ErrorMessage
                  className="error-form"
                  name="connection"
                  component="div"
                />
                {/* {isError && (
                  <p className="custom-p text-red-500 text-center">
                    {Something went wrong!}
                  </p>
                )} */}
              </div>
              <div className="flex flex-col items-center mt-4 mb-2">
                {isLoading ? (
                  <Spinner size={20} width={3} height={10} color="#ffffff" />
                ) : (
                  <Button
                    type="submit"
                    disabled={!isValid && !dirty}
                    onClick={handleSubmit}
                    className="relative disabled:opacity-30 m-auto px-10"
                  >
                    <span>Send</span>
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CustomForm;
