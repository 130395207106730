import { FC, MouseEvent } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from '@/components/common/Modal';
import ButtonIcon from '@/components/common/ButtonIcon';
import CustomForm from '@/components/FormModal/CustomForm';

interface IFormModalProps {
  handleKeyDown: (e: KeyboardEvent) => void;
  handleBackdropClick: (e: MouseEvent<HTMLDivElement>) => void;
  closeModal: () => void;
}

const FormModal: FC<IFormModalProps> = ({
  handleKeyDown,
  handleBackdropClick,
  closeModal,
}) => {
  return (
    <div className="relative">
      <Modal
        handleKeyDown={handleKeyDown}
        handleBackdropClick={handleBackdropClick}
        className="w-[100%] lg:w-[600px] xl:w-[40%] shadow-[0_0_10px_-8px_#000]"
      >
        <div className="overflow-y-auto relative">
          <CustomForm closeModal={closeModal} />
          <ButtonIcon
            onClick={closeModal}
            className="absolute top-2 right-[10px] hover:bg-gray-400/10 "
            aria-label="close modal"
          >
            <AiOutlineClose className="w-[2rem] h-[2rem]" />
          </ButtonIcon>
        </div>
      </Modal>
    </div>
  );
};

export default FormModal;
